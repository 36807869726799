import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import plane from '../../media/plane.jpg'
// import { Helmet } from 'react-helmet-async'


const AirFreight = () => {
  return (
    <>
      {/* <Helmet>
        <title>Air Freight - Horizon Blue Shipping</title>
        <meta name='description' content='Air Freight'/>
      </Helmet> */}
      <Header/>
      <div className="information">
          {/* <img src={plane} alt="plane" /> */}
          <div className="details">
              <h2 className='title'>Air Freight</h2>
              <div className="section">
                <div className="section-content">
                  <h3>Air Freight Services</h3>
                  <p>We know that for urgent or time-sensitive consignments, air freight forwarding is more often is the most suitable method of transport - we understand that speed and reliability are of the essence.</p>
                  <p>Air Freight is the transfer and shipment of goods via an air carrier, which may be consolidated or commercial.</p>
                  <p>Horizon Blue Shipping Ltd specialize in the movement of goods of any size and weight via air freight. We deal with different goods such as seafood, pharmaceuticals, electronics , fruits and all other shipments. We are also able to arrange collection of your goods from any UK address to be shipped to any airport worldwide.</p>
                  <p>As skilled staff , we provide reasonable price for shipment and an excellent customer services. It is ideally used by customer looking for speed, safety and reliability.</p>
                  <p>To make a booking we require a completed packing list with your details for shipment, this will be provided alongside your quotation and can easily be completed on the form provided. Once booked we will provide full flight details for you to track your shipment with a copy of your air waybill number and contact details of the required overseas clearing agent.</p>
                  <p>*Please note: Price is to destination airport only. Consignee is responsible for all additional costs incurred at the destination.</p>
                  <div className="list">
                    <p>There are many benefits for using air freight and are:</p>
                    <p className='list-item'>•	Weekly service to all worldwide destinations,</p>
                    <p className='list-item'>•	Added security,</p>
                    <p className='list-item'>•	Quick transfer times,</p>
                    <p className='list-item'>•	Often cheaper, and</p>
                    <p className='list-item'>•	UK collection service available</p>
                  </div>
                </div>
              </div>
          </div>
      </div>
      <Footer/>
    </>
  )
}

export default AirFreight