import React from 'react'

const LocationsInfo = () => {
  return (
    <div className='locations-info-cont'>
        <div className="locations-info">
            <h2>Who are we?</h2>
            <div className="locations-info-content">
                 <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9676917.144410787!2d41.575766980921536!3d5.217953043860209!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3d58425955ce6b53%3A0xbb20eaaa52cc59d9!2sMogadishu%2C%20Somalia!5e0!3m2!1sen!2suk!4v1687104341924!5m2!1sen!2suk" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                 <div className="locations-information">
                    <p>Horizon Blue Freight by Sea/Air, and Transportation provides on outstanding customer service, offering shipping to Somalia from the UK for business, international NGO’s and individuals.</p>
                    <p>We provide cost effective freight services when shipping to Somalia from the UK. We provide shipping, import clearance, storage, and transportation in Somalia.</p>
                    <p>We provide door to door service to individuals sending parcels, personal belongings, or any size freight to Somalia safely and reliably.</p>
                    
                 </div>
            </div>
        </div>
    </div>
  )
}

export default LocationsInfo