import horizonBlue from '../media/horizon-blue.png'
import { useNavigate } from 'react-router-dom'


const Header = () => {
    const nav = useNavigate();

    const openSidebar = () => {
      const sidebar = document.getElementById('sidebar');
      sidebar.style.display = 'block';
    }

    const closeSidebar = () => {
      const sidebar = document.getElementById('sidebar');
      sidebar.style.display = 'none';
    }

  return (
    <header>
         <div className='nav'>
            <img src={horizonBlue} alt="sailing ship" onClick={()=>{nav("/")}}/>
            <div className='nav-options'>
                <div onClick={()=>{nav("/about-us")}} className='option'>About Us</div>
                <div id='services' className='option'>Our Services
                  <div className="dropdown-cont">
                    <div className="dropdown">
                      <div className="dropdown-outer-div">
                        <h1 className='outer-div-title' onClick={()=>{nav("/services/sea-freight")}}>Sea Freight</h1>
                        <div className="inner-div">
                          <h3>Personal Effects & Groupage</h3>
                          <h3>Full Container</h3>
                          <h3>Vehicle Export</h3>
                          <h3>Door to Door (Somalia)</h3>
                        </div>
                      </div>
                      <div className="dropdown-outer-div">
                        <h1 className='outer-div-title' onClick={()=>{nav("/services/air-freight")}}>Air Freight</h1>
                        <div className="inner-div">
                          <h3>All Shipments</h3>
                          <h3>Fruits</h3>
                          <h3>Pharmaceuticals</h3>
                          <h3>Electronics</h3>
                        </div>
                      </div>
                      {/* <div className="dropdown-service" onClick={()=>{nav("/services/road-freight")}}>Road Freight</div> */}
                      <div className="dropdown-outer-div">
                        <h1 className='outer-div-title'>Export/Import Services</h1>
                      </div>
                      <div className="dropdown-outer-div">
                       <h1 className='outer-div-title'>Custom Clearance</h1>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div onClick={()=>{nav("/quote")}} className='option' >Quote</div> */}
                <div id='locations' className='option'>Locations
                  <div className="dropdown-cont">
                      <div className="dropdown">
                        <div className="dropdown-outer-div" >
                          <h1 className='outer-div-title'>Africa</h1>
                          <div className="inner-div">
                            <h3>Somalia</h3>
                            <h3>Horn Of Africa</h3>
                            <h3>East Africa</h3>
                            <h3>North Africa</h3>
                            <h3>West Africa</h3>
                            <h3>Southern Africa</h3>
                          </div>
                        </div>
                        {/* <div className="dropdown-outer-div" onClick={()=>{nav("/services/air-freight")}}>
                          <h1 className='outer-div-title'>Air Freight</h1>
                          <div className="inner-div">
                            <h3>All Shipments</h3>
                            <h3>Fruits</h3>
                            <h3>Pharmaceuticals</h3>
                            <h3>Electronics</h3>
                          </div>
                        </div> */}
                        {/* <div className="dropdown-service" onClick={()=>{nav("/services/road-freight")}}>Road Freight</div> */}
                        <div className="dropdown-outer-div" >
                          <h1 className='outer-div-title'>Europe</h1>
                        </div>
                        <div className="dropdown-outer-div" >
                          <h1 className='outer-div-title'>Asia</h1>
                        </div>
                        <div className="dropdown-outer-div" >
                          <h1 className='outer-div-title'>North America</h1>
                        </div>
                      </div>
                    </div>
                </div>
                <div onClick={()=>{nav("/contact-us")}} className='option'>Contact Us</div>
            </div>
        </div>
        <div id='open-sidebar' onClick={()=>{openSidebar()}}>☰</div>
        <div id="sidebar">
            <div className="sidebar-cont">
                <div id="close-sidebar" onClick={()=>{closeSidebar()}}>X</div>
                <div onClick={()=>{nav("/about-us")}} className='option'>About Us</div>
                <div id='services' className='option'>Our Services
                  <div className="dropdown-cont">
                      <div className="dropdown">
                        <div className="dropdown-outer-div">
                          <h1 className='outer-div-title' onClick={()=>{nav("/services/sea-freight")}}>Sea Freight</h1>
                          <div className="inner-div">
                            <h3>Personal Effects & Groupage</h3>
                            <h3>Full Container</h3>
                            <h3>Vehicle Export</h3>
                            <h3>Door to Door (Somalia)</h3>
                          </div>
                        </div>
                        <div className="dropdown-outer-div">
                          <h1 className='outer-div-title' onClick={()=>{nav("/services/air-freight")}}>Air Freight</h1>
                          <div className="inner-div">
                            <h3>All Shipments</h3>
                            <h3>Fruits</h3>
                            <h3>Pharmaceuticals</h3>
                            <h3>Electronics</h3>
                          </div>
                        </div>
                        {/* <div className="dropdown-service" onClick={()=>{nav("/services/road-freight")}}>Road Freight</div> */}
                        <div className="dropdown-outer-div">
                          <h1 className='outer-div-title'>Export/Import Services</h1>
                        </div>
                        <div className="dropdown-outer-div">
                          <h1 className='outer-div-title'>Custom Clearance</h1>
                        </div>
                      </div>
                    </div>
                </div>
                {/* <div onClick={()=>{nav("/quote")}} className='option' >Quote</div> */}
                <div id='locations' className='option'>Locations
                  <div className="dropdown-cont">
                      <div className="dropdown">
                        <div className="dropdown-outer-div" >
                          <h1 className='outer-div-title'>Africa</h1>
                          <div className="inner-div">
                            <h3>Somalia</h3>
                            <h3>Horn Of Africa</h3>
                            <h3>East Africa</h3>
                            <h3>North Africa</h3>
                            <h3>West Africa</h3>
                            <h3>Southern Africa</h3>
                          </div>
                        </div>
                        {/* <div className="dropdown-outer-div" onClick={()=>{nav("/services/air-freight")}}>
                          <h1 className='outer-div-title'>Air Freight</h1>
                          <div className="inner-div">
                            <h3>All Shipments</h3>
                            <h3>Fruits</h3>
                            <h3>Pharmaceuticals</h3>
                            <h3>Electronics</h3>
                          </div>
                        </div> */}
                        {/* <div className="dropdown-service" onClick={()=>{nav("/services/road-freight")}}>Road Freight</div> */}
                        <div className="dropdown-outer-div" >
                          <h1 className='outer-div-title'>Europe</h1>
                        </div>
                        <div className="dropdown-outer-div" >
                          <h1 className='outer-div-title'>Asia</h1>
                        </div>
                        <div className="dropdown-outer-div" >
                          <h1 className='outer-div-title'>North America</h1>
                        </div>
                      </div>
                  </div>
                </div>
                <div onClick={()=>{nav("/contact-us")}} className='option'>Contact Us</div>
            </div>
          </div>
    </header>
  )
}

export default Header