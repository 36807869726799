// import { Helmet } from 'react-helmet-async'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import ship from '../../media/ship.jpg'


const SeaFreight = () => {
  return (
    <>
      {/* <Helmet>
        <title>Sea Freight - Horizon Blue Shipping</title>
        <meta name='description' content='Sea Freight'/>
      </Helmet> */}
      <Header/>
      <div className="information">
          {/* <img src={ship} alt="ship" /> */}
          <div className="details">
              <h2 className='title'>Sea Freight</h2>
              <div className="section">
                <div className="section-content">
                  <h3>Personal Effects</h3>
                  <p>We specialize in the movement of goods of any size / weight. From personal effects to full container, we deal both groupage and full container.</p>
                  <p>We can arrange collection of your goods from any UK address to be shipped to any destination Somalia via our groupage sea freight service.</p>
                  <p>We  negotiate competitive rates to  Somalia and all worldwide destinations. This enables our groupage sea freight service to be reasonable price, safe and fast to all customers. </p>
                  <p>To make a booking, you contact to our email, then you will receive form for booking that states information required for booking. Once booking form reach to us, you will get offer and we can arrange a collection date suitable to the customer. We also provide package facilities such as barrel and Sack at reasonable price. Complete shipment details along with the required overseas agents will then follow.</p>
                  <p>We provide door to door to customers who ship to all destinations in Somalia such as  Mogadishu, Berbera, Bossaso and Kismayu.</p>
                  <p>We ship all sizes of personal effects.</p>
                </div>
                <div className="section-content">
                  <h3>Less Container Load/Groupage</h3>
                  <p>Less than Container Load (LCL) shipping provides an economical and flexible option for transporting smaller consignments from the UK to Somalia via sea freight. </p>
                  <p>In LCL shipping, your cargo is combined with other shipments within a shared container, allowing you to pay only for the space your goods occupy rather than the entire container. </p>
                  <p>This service suits small businesses or individuals with modest freight volumes requiring more flexible shipping schedules.</p>
                  <p>Less container load handled our freight  team carefully manages the consolidation and deconsolidation of LCL shipments, ensuring that your cargo is handled with maximum safety procedures. </p>
                  <p>Choose LCL shipping for a reasonable price  that securely and efficiently delivers your goods to all Somalia ports.</p>
                </div>
                <div className="section-content">
                  <h3>Full Container Shipment</h3>
                  <p>Full container shipping offers a the fastest and safest way to ship your goods to Somalia and all other destinations of the world. </p>
                  <p>Horizon Blue Shipping LTD offers a comprehensive range of professional sea freight services from imports / exports to door to door services, we deal all shipper demand. </p>
                  <p>We offer shipping service, specializing in the shipment of all types of commercial and personal goods to/from  Somalia and any worldwide destination.</p>
                  <p>Horizon Blue Shipping LTD provide a container at the shippers UK address for loading. We also offer the service of placing your container on the ground making loading of your container a lot easier. We advise client on procedure of goods handling from the shipper site to carrier and at port of destination. We provide any services required by the customers loading, transportation, documentation, and export/import services.</p>
                  <p>We use all types of Shipping Containers such as Dry, Reefer, Open Top or Flat Rack, Horizon Blue Shipping provide Freight by Sea/Air and Transportation have  covered these services.</p>
                </div>
                <div className="section-content">
                  <h3>Vehicle Export</h3>
                  <p>Horizon Blue shipping Ltd provides services to car export to Somalia , Africa and all over the world. Shipper can book container and skilled staff at their own site or agreed place. We can arrange the collection of all types of vehicles from all areas of the UK to be delivered directly into the necessary port for shipping overseas, easily, safely and hassle free.</p>
                  <p>We deal to all types of vehicles, tractors, and machinery. Customers will be guided by our skilled team from export required documents, shipping document and documentation at the port of destination.</p>
                </div>

              </div>
            
          </div>
      </div>
      <Footer/>
    </>
  )
}

export default SeaFreight