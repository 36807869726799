import React from 'react'

const Info = () => {
  return (
    <div className='info-container'>
        <div className="info">
          <div className="info-content">
            <h2>Unbeatable prices</h2>
            <p>Our worldwide shipping services will not be beaten on price to any African destination.</p>
          </div>
          <div className="info-content">
            <h2>Vehicle shipping</h2>
            <p>Our speciality is the shipment of all types of cars, 4x4s, vans, buses, trucks, trailers, plant & machinery.</p>
          </div>
          <div className="info-content">
            <h2>Experienced staff</h2>
            <p>Our team of friendly, mature staff have a wealth of experience and are able to offer the best help advice throughout your shipment.</p>
          </div>
          <div className="info-content">
            <h2>By air, by sea</h2>
            <p>Horizon Blue Shipping also offers containerized, conventional & air freight shipments to most worldwide destinations.</p>
          </div>
          <div className="info-content">
            <h2>Shipping specialists</h2>
            <p>Established in 2023, we pride ourselves on our excellent customer service & support.</p>
          </div>
          <div className="info-content">
            <h2>All goods</h2>
            <p>We ship containers, commercial goods, personal effects & much more.</p>
          </div>
        </div>
    </div>
  )
}

export default Info