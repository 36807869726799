import plane from '../media/plane.jpg'
import ship from '../media/ship.jpg'
import truck from '../media/truck.jpg'
import { useNavigate } from 'react-router-dom'


const Services = () => {
    const nav = useNavigate();
  return (
    <div className="services-cont">
        <div className='services'>
            <h1>Services We Offer</h1>
            <div className="services-grid">

                <div className="service" onClick={()=>{nav('/services/sea-freight')}}>
                    <h3>Sea Freight</h3>
                    <img src={ship} alt="plane" />

                </div>

                <div className="service" onClick={()=>{nav('/services/air-freight')}}>
                    <h3>Air Freight</h3>
                    <img src={plane} alt="plane" />
                </div>


                {/* <div className="service" onClick={()=>{nav('/services/road-freight')}}>
                    <h3>Road Freight</h3>
                    <img src={truck} alt="plane" />
                </div>

                <div className="service" onClick={()=>{nav('/services/sea-freight')}}>
                    <h3>Sea Freight</h3>
                    <img src={ship} alt="plane" />
                </div> */}

                {/* <div className="service">Service 4</div>
                <div className="service">Service 5</div> */}
            </div>
        </div>
    </div>
  )
}

export default Services