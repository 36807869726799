import Header from '../components/Header'
import Footer from '../components/Footer'
// import { Helmet } from 'react-helmet-async'

const Contact = () => {
  return (
    <>
      {/* <Helmet>
        <title>Contact Us - Horizon Blue Shipping</title>
        <meta name='description' content='Contact Us'/>
      </Helmet> */}
      <Header/>
      <div className="contact-us-information">
          <div>
              <h2>For any business enquiries email: <span id='diffCol'>enquiries@horizonblueshipping.com</span> </h2>
              <h2>For any bookings email: <span id='diffCol'>bookings@horizonblueshipping.com</span> </h2>
              <h2>For any complaints email: <span id='diffCol'>complaints@horizonblueshipping.com</span> </h2>
          </div>

          
      </div>
      <Footer/>
    </>
  )
}

export default Contact