import React from 'react'

const Footer = () => {
    const d = new Date();
  return (
    <footer>
      <div>
          <h2 className='title'>Headquarters:</h2>
          <h2>Horizon Blue Shipping, </h2>
          <h2>Freight by Sea/Air and Transportation</h2>
          <h2>123 High Street, W3 6LY</h2>
          <h2>London, UK</h2>
      </div>
      <div>
          <h2 className='title'>Branch:</h2>
          <h2>Embassy Hotel</h2>
          <h2>Makka Al-Mukarama Street</h2>
          <h2>Hodan, Mogadishu</h2>
          <h2>Somalia</h2>
      </div>
      <div>
          <h2>Copyright &copy; {d.getFullYear()} </h2>
          <h2>Horizon Blue Shipping Ltd. </h2>
          <h2>All Rights Reserved.</h2>
      </div>
    </footer>
  )
}

export default Footer