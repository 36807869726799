import './App.css';
import {Routes, Route} from 'react-router-dom';
import Home from './pages/Home';
import AirFreight from './pages/services/AirFreight';
import SeaFreight from './pages/services/SeaFreight';
import RoadFreight from './pages/services/RoadFreight';
import Contact from './pages/Contact';
import About from './pages/About';
import Locations from './pages/Locations';
import Quote from './pages/Quote';
import PageNotFound from './pages/PageNotFound';



function App() {
  return (
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/services/air-freight' element={<AirFreight/>}/>
      <Route path='/services/sea-freight' element={<SeaFreight/>}/>
      {/* <Route path='/services/road-freight' element={<RoadFreight/>}/> */}
      <Route path='/contact-us' element={<Contact/>}/>
      <Route path='/about-us' element={<About/>}/>
      {/* <Route path='/locations' element={<Locations/>}/> */}
      {/* <Route path='/quote' element={<Quote/>}/> */}
      <Route path='/*' element={<PageNotFound/>}/>
    </Routes>
  );
}

export default App;
