import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
// import { Helmet } from 'react-helmet-async'

const About = () => {
  return (
    <>
    {/* <Helmet>
      <title>About Us - Horizon Blue Shipping</title>
      <meta name='description' content='About Us'/>
    </Helmet> */}
    <Header/>
    <div className="about-us-information">
       
        <div className="company-info">
            <h2 className='company-info-title'>What We Do</h2>
            <div className="info-section">
              <p>After long discussion among experienced exporters / importers and professional of international trade (specialized in physical movement of goods), they decided to establish Horizon Blue Shipping, Freight by Sea/Air and Transportation, to provide reasonable freight fees to shippers in and out of Somalia and worldwide.</p>
            </div>
            <div className="info-section">
              <p>Horizon Blue Shipping is a small shipping company based in London, UK and have a branch in Mogadishu, Somalia. It’s objectives included to provide competitive price and high quality services to shippers. It also confers fast and safe shipment to Somalia and worldwide.</p>
            </div>
            <div className="info-section">
              <p>There is growing demand in the Somali market due to high import of all sections from commodity, essential foods, textile, chemicals, pharmaceuticals, and energy. Somalia is a gate to neighbouring countries such as Ethiopia and Kenya. Our services quality will attract more shippers in the area and projected swift growth in near future.</p>
            </div>
            <div className="info-section">
              <p>We are totally independent which we believe is another one of our key strengths. This provides us with complete control over our operations, suppliers and business partners and allows us to deliver a very personal service whereby our clients deal with the same responsive and knowledgeable team member each time they call.</p>
            </div>
            <div className="info-section">
              <p>Proud of the relationships we build with our clients, we become an extension of their teams and true logistics partner because we understand their shipping requirements.  It is our personal commitment to maintain both cost and service advantages in a prompt fashion throughout every step of the shipment process.</p>
            </div>
            <div className="info-section">
              <p>If you require any further information, guidance or advice in respect of our company, please do not hesitate to contact us.</p>
            </div>
          
        </div>
        <div className="divider"></div>
        <div className="location">
            <h2>Our Office</h2>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.3604196157426!2d-0.271281323598419!3d51.506603371813135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760e1ae1429b01%3A0x669e6198cf36a6ed!2s123%20High%20St%2C%20London%20W3%206LY!5e0!3m2!1sen!2suk!4v1687073545538!5m2!1sen!2suk" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

        
    </div>
    <Footer/>
</>
  )
}

export default About