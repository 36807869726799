import promoVideo from '../media/horizon-blue.mp4';
import horizonBlue from '../media/horizon-blue.png'
import Footer from '../components/Footer';
import Services from '../components/Services';
import Info from '../components/Info';
import Cargo from '../components/Cargo';
import { useNavigate } from 'react-router-dom';
import LocationsInfo from '../components/LocationsInfo';
// import { Helmet } from 'react-helmet-async';

const Home = () => {
    const nav = useNavigate()

    
    const openSidebar = () => {
        const sidebar = document.getElementById('sidebar');
        sidebar.style.display = 'block';
      }
  
      const closeSidebar = () => {
        const sidebar = document.getElementById('sidebar');
        sidebar.style.display = 'none';
      }

  return (
    <>
    {/* <Helmet>
      <title>Horizon Blue Shipping</title>
      <meta name='description' content='Horizon Blue Shipping Ltd.'/>
    </Helmet> */}
    <div className='hero'>
        <nav className='nav'>
            <img src={horizonBlue} alt="sailing ship" />
            <div className='ul'>
                 <div className='li' onClick={()=>{nav("/about-us")}}>About Us</div>
                <div className='li' id='services'>Our Services
                    <div className="dropdown-cont">
                        <div className="dropdown">
                            <div className="dropdown-outer-div">
                                <h1 className='outer-div-title' onClick={()=>{nav("/services/sea-freight")}}>Sea Freight</h1>
                                <div className="inner-div">
                                    <h3>Personal Effects & Groupage</h3>
                                    <h3>Full Container</h3>
                                    <h3>Vehicle Export</h3>
                                    <h3>Door to Door (Somalia)</h3>
                                </div>
                            </div>
                            <div className="dropdown-outer-div">
                                <h1 className='outer-div-title' onClick={()=>{nav("/services/air-freight")}}>Air Freight</h1>
                                <div className="inner-div">
                                    <h3>All Shipments</h3>
                                    <h3>Fruits</h3>
                                    <h3>Pharmaceuticals</h3>
                                    <h3>Electronics</h3>
                                </div>
                            </div>
                            {/* <div className="dropdown-service" onClick={()=>{nav("/services/road-freight")}}>Road Freight</div> */}
                            <div className="dropdown-outer-div">
                                <h1 className='outer-div-title'>Export/Import Services</h1>
                            </div>
                            <div className="dropdown-outer-div">
                                <h1 className='outer-div-title'>Custom Clearance</h1>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='li' onClick={()=>{nav("/quote")}}>Quote</div> */}
                <div className='li' id='locations' >Locations
                    <div className="dropdown-cont">
                        <div className="dropdown">
                            <div className="dropdown-outer-div" >
                                <h1 className='outer-div-title'>Africa</h1>
                                <div className="inner-div">
                                    <h3>Somalia</h3>
                                    <h3>Horn Of Africa</h3>
                                    <h3>East Africa</h3>
                                    <h3>North Africa</h3>
                                    <h3>West Africa</h3>
                                    <h3>Southern Africa</h3>
                                </div>
                            </div>
                            {/* <div className="dropdown-outer-div" onClick={()=>{nav("/services/air-freight")}}>
                                <h1 className='outer-div-title'>Air Freight</h1>
                                <div className="inner-div">
                                <h3>All Shipments</h3>
                                <h3>Fruits</h3>
                                <h3>Pharmaceuticals</h3>
                                <h3>Electronics</h3>
                                </div>
                            </div> */}
                            {/* <div className="dropdown-service" onClick={()=>{nav("/services/road-freight")}}>Road Freight</div> */}
                            <div className="dropdown-outer-div" >
                                <h1 className='outer-div-title'>Europe</h1>
                            </div>
                            <div className="dropdown-outer-div" >
                                <h1 className='outer-div-title'>Asia</h1>
                            </div>
                            <div className="dropdown-outer-div" >
                                <h1 className='outer-div-title'>North America</h1>    
                            </div>
                        </div>
                    </div>
                </div>
                <div className='li' onClick={()=>{nav("/contact-us")}}>Contact Us</div>
            </div>
            <div id='open-sidebar' className='homeSidebar' onClick={()=>{openSidebar()}}>☰</div>
            <div id="sidebar">
            <div className="sidebar-cont">
                <div id="close-sidebar" onClick={()=>{closeSidebar()}}>X</div>
                <div onClick={()=>{nav("/about-us")}} className='option'>About Us</div>
                <div id='services' className='option'>Our Services
                  <div className="dropdown-cont">
                    <div className="dropdown">
                        <div className="dropdown-outer-div">
                            <h1 className='outer-div-title' onClick={()=>{nav("/services/sea-freight")}}>Sea Freight</h1>
                            <div className="inner-div">
                                <h3>Personal Effects & Groupage</h3>
                                <h3>Full Container</h3>
                                <h3>Vehicle Export</h3>
                                <h3>Door to Door (Somalia)</h3>
                            </div>
                        </div>
                        <div className="dropdown-outer-div">
                            <h1 className='outer-div-title' onClick={()=>{nav("/services/air-freight")}}>Air Freight</h1>
                            <div className="inner-div">
                                <h3>All Shipments</h3>
                                <h3>Fruits</h3>
                                <h3>Pharmaceuticals</h3>
                                <h3>Electronics</h3>
                            </div>
                        </div>
                      {/* <div className="dropdown-service" onClick={()=>{nav("/services/road-freight")}}>Road Freight</div> */}
                      <div className="dropdown-outer-div">
                        <h1 className='outer-div-title'>Export/Import Services</h1>
                      </div>
                      <div className="dropdown-outer-div">
                        <h1 className='outer-div-title'>Custom Clearance</h1>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div onClick={()=>{nav("/quote")}} className='option' >Quote</div> */}
                <div id='locations' className='option'>Locations
                    <div className="dropdown-cont">
                        <div className="dropdown">
                            <div className="dropdown-outer-div" >
                            <h1 className='outer-div-title'>Africa</h1>
                            <div className="inner-div">
                                <h3>Somalia</h3>
                                <h3>Horn Of Africa</h3>
                                <h3>East Africa</h3>
                                <h3>North Africa</h3>
                                <h3>West Africa</h3>
                                <h3>Southern Africa</h3>
                            </div>
                            </div>
                            {/* <div className="dropdown-outer-div" onClick={()=>{nav("/services/air-freight")}}>
                            <h1 className='outer-div-title'>Air Freight</h1>
                            <div className="inner-div">
                                <h3>All Shipments</h3>
                                <h3>Fruits</h3>
                                <h3>Pharmaceuticals</h3>
                                <h3>Electronics</h3>
                            </div>
                            </div> */}
                            {/* <div className="dropdown-service" onClick={()=>{nav("/services/road-freight")}}>Road Freight</div> */}
                            <div className="dropdown-outer-div" >
                                <h1 className='outer-div-title'>Europe</h1>
                            </div>
                            <div className="dropdown-outer-div" >
                                <h1 className='outer-div-title'>Asia</h1>
                            </div>
                            <div className="dropdown-outer-div" >
                                <h1 className='outer-div-title'>North America</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={()=>{nav("/contact-us")}} className='option'>Contact Us</div>
            </div>
            </div>
        </nav>
        <div className="content-cont">
            <div className='content'>
                {/* <h2>The <span id='differentColour'>new</span> world class <br/>shipping company</h2> */}
                {/* <h2>Global shipping made easy</h2> */}
                <h2>Specialist shipping to Somalia</h2>
                <div className="line"></div>
                <div className="extra-info">
                    {/* <h2>Our specialists provide timely and cost-effective shipping to Somalia.</h2> */}
                    <h2>Our teams work with importers / exporters, businesses, individuals and international NGO’s, providing cost effective solutions to keep international supply chains moving.</h2>
                    {/* <h2>Horizon Blue Freight by Sea/Air, and Transportation provides on  outstanding customer service, offering shipping  to Somalia from the UK for business, international NGO’s and individuals.</h2>
                    <h2>We provide cost effective freight services when shipping to Somalia from the UK. We provide shipping, import clearance, storage, and transportation in Somalia.</h2>
                    <h2>We provide door to door service to individuals sending parcels, personal belongings, or any size freight to Somalia safely and reliably.</h2> */}
                    {/* <h2 className="call-to-action" onClick={()=>{nav("/quote")}}>Get a quote</h2> */}
                </div>
                
            </div>
        </div>

            {/* <h2 className="call-to-action" onClick={()=>{nav("/quote")}}>Get a quote</h2> */}
        <video autoPlay loop muted playsInline className='bg-video'>
            <source src={promoVideo} type='video/mp4' />
        </video>
    </div>
    <LocationsInfo/>
    <Info/>
    <Services/>
    <Cargo/>
    <Footer/>
    </>
  )
}

export default Home