import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
// import { Helmet } from 'react-helmet-async'

const PageNotFound = () => {
  return (
    <>
    {/* <Helmet>
      <title>Page Not Found - Horizon Blue Shipping</title>
      <meta name='description' content='Page Not Found'/>
    </Helmet> */}
    <Header/>
    <div className="page-not-found">
        <h1>Page Not Found</h1>
    </div>
    <Footer/>
    </>
  )
}

export default PageNotFound