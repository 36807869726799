import React from 'react'

const Cargo = () => {
  return (
    <div className='cargo-container'>
        <div className="cargo">

            <h2>Types Of Cargo We Transport</h2>
            <div className="cargo-cont">
                <div className="cargo-type">
                    <h2>Oil & Gas</h2>
                    <p>In house specialist knowledge moving materials and equipment around the world</p>
                </div>
                <div className="cargo-type">
                    <h2>Manufacturing</h2>
                    <p>Delivering bespoke solutions supporting business objectives to operate an efficient supply chain</p>

                </div>
                <div className="cargo-type">
                    <h2>Chemicals</h2>
                    <p>Fully versed in the compliance and regulation requirements of transporting hazardous goods</p>

                </div>
                <div className="cargo-type">
                    <h2>Exhibitions & Events</h2>
                    <p>Providing safe and timely transportation of fragile and oversized items</p>

                </div>
            </div>
        </div>
    </div>
  )
}

export default Cargo